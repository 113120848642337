<template>
 <div class="page-container">
	 <p class="main-title" >{{$route.query.main_title}}<a @click="reload"><i class="el-icon-refresh"></i></a></p>
	 <div class="page-desc">
	     获取应用ID和应用秘钥 
		<i @click="question(9)"  class="el-icon-question" style="color:#909399;font-size: 16px;"></i>     
	 </div>  
	   <div class="search-form">
	     <el-form ref="form" :inline="true"  :model="search_key" prop="">
	         <el-form-item label="企业">
	             <el-input v-model="search_key.title" placeholder="输入企业名称或联系人姓名"></el-input> 
	         </el-form-item>
	         <el-button type="primary" @click="getData()">查询</el-button>
			 <el-button type="primary" @click="handleExport()">导出</el-button>
	     </el-form>
	  </div>
	 <div style="margin-bottom:10px;">
	      <el-button size="small" type="" @click="handleAdd()">添加</el-button>
	  </div> 
	  <el-table
	     :data="tableData" 
	     :span-method="objectSpanMethod"
	     border
	     style="width:100%">
	     <el-table-column
	         prop="coid"
	         label="ID">
	     </el-table-column>
	      <el-table-column
	         prop="co_title"
	         label="企业">
	     </el-table-column>
	     <el-table-column 
	         prop="contact"
	         label="联系人">
	     </el-table-column>
	     <el-table-column
	         prop="corpid"
	         label="企业ID">
	     </el-table-column>
	      <el-table-column
	         prop="agentid"
	         label="应用ID">
	     </el-table-column>
	     <el-table-column
	         prop="desc"
	         label="企业备注">
	     </el-table-column>
	     <el-table-column
	         prop="name"
	         label="企业秘钥">
	         <template slot-scope="scope">
	             <span style="color:#409EFF">{{scope.row.name}}</span>
	         </template>
	     </el-table-column>
	      <el-table-column
	         width="250"
	         prop="secret"
	         label="秘钥">
	         <template slot-scope='scope'>
	             <!-- <textarea  v-model="scope.row.secret" type="password"></textarea> -->
	             <el-input v-model="scope.row.secret" class="secret" type="password"  :value="scope.row.secret" show-password @change="changeSecret(scope.row)" />
	         </template>
	     </el-table-column>
	       <el-table-column
	         prop="status"
	         label="企业状态">
	         <template slot-scope="scope">
	             <span v-if="scope.row.status == 1" style='color:#67C23A'>启用</span>
	              <span v-else  style="color:#F56C6C">禁用</span>
	         </template>
	     </el-table-column>
	     <el-table-column
	         label="操作">
	         <template slot-scope="scope">
	             <el-button  size="small" @click="handleAdd(scope.row.coid)">编辑</el-button>
	             <el-button  size="small" type="danger" plain  @click="handleDel(scope.row)">删除</el-button>
	         </template>
	     </el-table-column>
	  </el-table>
	  <pagination-view :table-data-total='total' @currPage='getCurrPage' @pageSize='getPageSize'></pagination-view>
	<AddVue ref="childDialog"></AddVue>
	<DocVue ref="docDialog"></DocVue>
 </div>
</template>

<script>
import AddVue from './Add.vue'
import store from "../../store"
import DocVue  from '../Doc.vue'
export default {
	 inject:['reload'],
	components:{
	    AddVue,
		 DocVue
	},
    data() {
        return {
			search_key:{
			    title:''
			},
			tableData:[],
			currentPage:1,
			pagesize:10,
			total:0,
			secret:''
        }
    },
    created() {
		this.getData()
    },
    mounted() {

    },
    methods:{
		changeSecret(row){        //更新秘钥
		     this.$api.put('company/updateSecret',{id:row.id,secret:row.secret},res=>{
		         if(res.code == 200){
		             this.$message.success(res.msg)
		             this.reload()
		         }else{
		             this.$message.error(res.msg)
		         }
		     })
		},
		question(){
			this.$refs.docDialog.getDoc(9)
		},
		getData(){   //获取企业数据
		    this.$api.post('company/index',{
		        page:this.currentPage,
		        limit:this.pagesize,
		        title:this.search_key.title
		    },res=>{
		        if(res.code == 200){
		             this.tableData =  res.data.list
		             this.total =  res.data.count
		        }else{
		            this.$message.error(res.msg)
		        }
		    })
		},
		objectSpanMethod({ row, column, rowIndex, columnIndex }) {
		     if (columnIndex  < 6 || columnIndex == 8 || columnIndex == 9) {
		         if (rowIndex % 2 === 0) {
		             return {
		             rowspan: 2,
		             colspan: 1
		             };
		         } else {
		             return {
		             rowspan: 0,
		             colspan: 0
		             };
		         }
		     }
		 },
		handleAdd(id){
			this.$refs.childDialog.handleAdd(id)
		},
		handleDel(row){
		      this.$alert("确定删除该企业数据吗？", "删除提示", {
		         // confirmButtonText:'确定',
		         showConfirmButton: true,
		         showCancelButton: true,
		         callback: action => {
		             if (action == "confirm") {
		                 this.$api.delete("company/del", { "id": row.coid }, res => {
		                     if (res.code == 200) {
		                         this.$message.success(res.msg);
		                         this.reload();
		                     }
		                 });
		             }
		             else if (action == "cancel") {
		                 this.reload();
		             }
		         }
		     });
		},
		  getCurrPage(val){   //获取当前页
		     this.currentPage =  val
		     this.getData()
		 },
		 getPageSize(val){   //获取每页条数
		     this.pagesize =  val
		     this.getData()
		 },
		handleExport(){	//导出
			this.$api.post('Company/export',this.search_key,res=>{
			    if(res.code == 200){
			        location.href = '/api/admin/Company/export?key='+res.data.key
			    }else{
			        this.$message.error(res.msg)
			    }
			})
		}
    },
}
</script>
<style scoped>

</style>