<template>
<div>
	<!-- 添加弹窗 -->
	<el-dialog title="添加企业" :visible.sync='dialogAddVisible'  width="45%" :close-on-click-modal="false" >
	    <el-form ref="form" :model="form" prop="" :rules="rules" label-width="100px">
			 <el-tabs>
				 <el-tab-pane label="基本信息" >
					  <el-form-item label="企业名称" prop="title">
						 <el-input  v-model="form.title"></el-input>
					 </el-form-item>
					 <el-form-item label="联系人" prop="contact">
						 <el-input  v-model="form.contact"></el-input>
					 </el-form-item>
					 <el-form-item label="企业ID" prop="corpid">
						 <el-input  v-model="form.corpid"></el-input>
					 </el-form-item>
					 <el-form-item label="AgentId" prop="agentid">
						 <el-input  v-model="form.agentid"></el-input>
					 </el-form-item>
					 <el-form-item label="应用密钥" prop="secret">
						 <div style="display:flex;margin-bottom:10px;" v-for="item in this.form.secret">
							 <template v-if="item.search_key =='app' || item.search_key == 'contacts'">
								 <el-input  v-model="item.id"  style="display:none;"></el-input>    
								 <el-input style="width:250px;margin-right:10px;"  v-model="item.name" placeholder='' disabled="true"></el-input>
								 <el-input  v-model="item.secret" placeholder='秘钥'></el-input>
							</template>
						 </div>
					 </el-form-item>
					 <el-form-item label="企业备注" prop="desc">
						 <el-input type="textarea" rows="5" v-model="form.desc"></el-input>
					 </el-form-item>
					 <el-form-item label="状态" prop="status">
						 <template>
							 <el-radio v-model="form.status" :label="0">禁用</el-radio>
							 <el-radio v-model="form.status" :label="1">启用</el-radio>
						 </template>
					 </el-form-item>
				 </el-tab-pane>
				<!-- <el-tab-pane label="微信支付" >
					<el-form-item label="商户号" prop="">
						<el-input  v-model="form.mch_id"></el-input>
					</el-form-item>
					 <el-form-item label="商户秘钥" prop="">
						<el-input  v-model="form.mch_id"></el-input>
					 </el-form-item>		
				 </el-tab-pane> -->
				 <el-tab-pane label="位置信息" >
					 <div  class="form-tips-words-color" style="margin-bottom: 10px;">
						 使用智能名片时会关联到以下数据,经纬度获取: 
						 <a href="https://map.jiqrxx.com/jingweidu/">https://map.jiqrxx.com/jingweidu/</a>
					</div>
					 <el-form-item label="地址" prop="agentid">			
						<el-input  v-model="form.address"></el-input>
					 </el-form-item>
					 <el-form-item label="纬度" prop="agentid">
						<el-input  v-model="form.address_latitude"></el-input>
					 </el-form-item>
					 <el-form-item label="经度" prop="agentid">
						<el-input  v-model="form.address_longitude"></el-input>
					 </el-form-item>
				 </el-tab-pane> 
			 </el-tabs>
	    </el-form>
	     <div slot="footer" class="dialog-footer">
	       <el-button @click="dialogAddVisible = false">取 消</el-button>
	       <el-button type="primary" @click="confirm()">确 定</el-button>
	   </div>
	</el-dialog>
</div>
</template>

<script>
export default {
	inject:['reload'],
    data() {
        return {
			dialogAddVisible:false,
			form:{
			    id:'',
			    title:'',
			    contact:'',
			    corpid:'',
			    agentid:'',
			  //   secret:'',
			    status:0,
			    secret:[],
				 hospital_id:'',
				 address:'',
				 address_longitude:'',
				 address_latitude:''
			},
			 // hospitalList:[],			//网盘项目账号
			rules:{
			     title:[
			          { required: true,message:'请输入企业标题',trigger: 'blur'}
			     ],
			     contact:[
			          { required: true,message:'请输入企业联系人',trigger: 'blur'}
			     ],
			     corpid:[
			         { required: true,message:'请输入企业ID',trigger: 'blur'}
			     ],
			     agentid:[
			          { required: true,message:'请输入应用ID',trigger: 'blur'}
			     ],
			     secret:[
			         { required: true,message:'请输入应用密钥',trigger: 'blur'}
			     ] 
			},
			secret:''
        }
    },
    created() {

    },
    mounted() {

    },
    methods:{
		handleAdd(id=0){
			if(id < 1){
				this.$api.get('company/add',null,res=>{
				    if(res.code == 200){
				        this.dialogAddVisible = true
					   // this.hospitalList =  res.data.hospital_list
					   this.form.secret =  res.data.secret??this.form.secret
				    }
				})
			}else{
				this.handleEdit(id)
			}
		},
		handleEdit(id){	//编辑数据
		    this.$api.get('company/edit',{'id':id},res=>{
		        if (res.code == 200) {
		            this.form =  res.data.info
					// this.hospitalList =  res.data.hospital_list
		            this.dialogAddVisible =  true
		        }else{
		            this.$message.error(res.msg)
		        }
		    })
		},
		confirm(){
		     let url = 'company/add'
		     if (this.form.id) {
		         url = 'company/edit'
		     }  
		     this.$api.post(url,this.form,res=>{
		         if (res.code  == 200) {
		            this.dialogAddVisible =  false
		            this.$message.success(res.msg)
		            this.reload();   
		            this.$refs["form"].resetFields();
		            
		         }else{
		             this.$message.error(res.msg)
		         }
		     }) 
		   
		},
    },
}
</script>
<style scoped>

</style>